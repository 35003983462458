const getMerchantDetail = () => {
  const merchantId = JSON.parse(localStorage.getItem('loggedUser')).merchant.id;
  return {
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_ADDRESS}/merchants/${merchantId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
};

const updateMerchantIBAN = (data) => {
  const merchantId = JSON.parse(localStorage.getItem('loggedUser')).merchant.id;
  return {
    method: 'patch',
    url: `${process.env.REACT_APP_SERVER_ADDRESS}/merchants/${merchantId}/iban`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    data,
  };
};

const updateMerchant = (data) => {
  const merchantId = JSON.parse(localStorage.getItem('loggedUser')).merchant.id;
  return {
    method: 'patch',
    url: `${process.env.REACT_APP_SERVER_ADDRESS}/merchants/${merchantId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    data,
  };
};

export { getMerchantDetail, updateMerchantIBAN, updateMerchant };
