import React from 'react';
import * as Icon from 'react-feather';

const navigationConfig = [
  {
    id: 'home',
    title: 'Anasayfa',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/merchant-panel',
  },
  {
    id: 'profile',
    title: 'Profil',
    type: 'item',
    icon: <Icon.Briefcase size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/merchant-panel/profile',
  },
  {
    id: 'transactions',
    title: 'İşlemler',
    type: 'item',
    icon: <Icon.Repeat size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/merchant-panel/transactions',
  },
  {
    id: 'endOfDay',
    title: 'Gün Sonu',
    type: 'item',
    icon: <Icon.Octagon size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/merchant-panel/endofday',
  },

  {
    id: 'employeesCollapse',
    title: 'Çalışanlar',
    type: 'collapse',
    icon: <Icon.User size={20} />,
    permissions: ['admin', 'editor'],
    children: [
      {
        id: 'employees',
        title: 'Çalışanlar Liste',
        type: 'item',
        icon: <Icon.List size={15} />,
        permissions: ['admin', 'editor'],
        navLink: '/merchant-panel/employees/list',
      },
      // {
      //   id: 'employeeAdd',
      //   title: 'Çalışan Ekle',
      //   type: 'item',
      //   icon: <Icon.Plus size={15} />,
      //   permissions: ['admin', 'editor'],
      //   navLink: '/merchant-panel/employees/add',
      // },
    ],
  },
  {
    id: 'settlements',
    title: 'Ödemeler',
    type: 'item',
    icon: <Icon.DollarSign size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/merchant-panel/settlements',
  },
  // {
  //   id: 'support',
  //   title: 'Destek',
  //   type: 'item',
  //   icon: <Icon.HelpCircle size={20} />,
  //   permissions: ['admin', 'editor'],
  //   navLink: '/merchant-panel/support',
  // },
];

export default navigationConfig;
